import $ from 'jquery';
import 'bootstrap';
import 'chairisher/view/helper/jquery';

import CookieBannerView from 'chairisher/view/cookie/cookiebanner';
import CookieUtils from 'chairisher/util/cookie';
import FavoriteProductView from 'chairisher/view/product/favorite';
import FavoritesNavMenu from 'chairisher/view/nav/favoritesnav';
import GlobalSearchView from 'chairisher/view/search/global';
import GoogleAnalyticsUtils from 'chairisher/util/googleanalytics';
import KeyUtils from 'chairisher/util/key';
import NavigationView from 'chairisher/view/navigation';
import PaypalStoreOffer from 'chairisher/thirdparty/paypalstoreoffer';
import RepeatingScroller from 'chairisher/component/scroller/repeating';
import TermsOfServiceAndPrivacyPolicyView from 'chairisher/view/account/tosapp';
import ViewportUtil from 'chairisher/util/viewport';

import bindInlineEmailSignup from 'chairisher/view/promo/inlineemailsignup';
import ensureCsrf from 'chairisher/util/csrf';
import runABTests from 'chairisher/abtest/runner';
import writeUtmzCookie from 'chairisher/analytics/utmz';
import zendeskWebWidgetInst from 'chairisher/component/zendesk';

import { trackAccountCreated } from 'chairisher/analytics/auth';
import { trackSuccessfulEmailFooterCollection } from 'chairisher/analytics/email';
import { getTestObjects } from 'chairisher/context/abtest';
import { getAccountDataPromise } from 'chairisher/context/account';
import { getCollectionId, isCollection, isShop } from 'chairisher/context/collection';
import { getCartCount } from 'chairisher/context/cart';
import { getRemarketingObject, storeUrlHash, storeUtmParams } from 'chairisher/context/tracking';
import {
    BannerPosition,
    GlobalPosition,
    initializeAnalytics,
    logAmplitudeLinkClickEvent,
    logError,
    trackRemarketingEvent,
} from 'chairisher/util/analytics';
import { FetchError } from 'chairisher/util/fetch';
import { isMaxPhone, isMaxTablet } from 'chairisher/util/mediaquery';
import { hasSeenPromotedListingsPopup } from 'chairisher/view/helper/promotedlistings';
import { shouldDisplayEmailMarketingModal } from 'chairisher/view/helper/marketingpromo';
import { isPromotedListingsPopupEnabled } from 'chairisher/context/promotedlistings';
import { getGuid, isAuthenticated, isIdentified, isStaff, wasAccountCreated } from 'chairisher/context/auth';
import {
    getAdvertisingCookiesCode,
    getCookiesPreferenceCode,
    getFunctionalCookiesCode,
    getPerformanceCookiesCode,
    isOptedOutOfCookie,
    setIsOptedOutOfCookie,
} from 'chairisher/context/cookies';
import { canRenderFavoriteHearts } from 'chairisher/context/product';

function attemptToShowPromotedListingsPopup() {
    // TODO (CHAIR-17049): fix issue where PL popup opens at same time as marketing modal

    const canShow =
        isPromotedListingsPopupEnabled() &&
        !hasSeenPromotedListingsPopup() &&
        !(getCollectionId() && isMaxTablet()) && // shouldn't appear on Mobile Web Collections
        $('.js-drawer-right').length === 0; // Mini-cart isn't open

    if (canShow) {
        import('chairisher/view/promotedlistings/popup').then(({ default: showPromotedListingsPopup }) => {
            setTimeout(() => {
                showPromotedListingsPopup();
            }, 4000);
        });
    }
}

/**
 * The base controller that all controllers inherit from to bind actions for a given page.
 * Changing code in this file affects every page's bundle size.
 * Global actions should be lazy-loaded if possible - for example, the mini-cart needs to be present on every page,
 * but most sessions don't open it, which makes it a good candidate.
 */
class Controller {
    constructor() {
        // Currently, this is only set when the child class is SearchController
        this.productGrid = null;
    }

    /**
     * Kicks off all of the bindings for the page.
     */
    async execute() {
        try {
            await this.updateContextWithUserData();
            // Only initialize analytics if account data call succeeds
            initializeAnalytics();
        } catch (e) {
            // it should be ok to continue with the controller execution if account data fails to fetch
            console.error(e);
        }

        try {
            this.bindAnalytics();
            this.bindAuthActions();
            this.bindCookieBanner();
            this.bindCartAction();
            this.bindPageAnalytics();
            this.bindComponents();
            this.bindPromos();
            this.bindNavigation();
            this.bindChairishActions();
            this.bindPageActions();

            if (isStaff()) {
                this.bindAdminActions();
            }

            // todo: (CHAIR-18033) remove when better CSRF solution is implemented
            $(document).on('visibilitychange', (e) => {
                if (document.visibilityState === 'visible') {
                    const csrfToken = CookieUtils.getValueFromCookie('csrftoken');
                    if (csrfToken) {
                        ensureCsrf();
                    }
                }
            });
        } catch (e) {
            // Basic error reporting to see if any controllers fail to execute
            logError('controller execute error', e);
        }
    }

    /**
     * Binds global actions for admin users
     */
    bindAdminActions() {
        import('chairisher/view/admin/spotlight').then(({ default: AdminSpotlightView }) => {
            new AdminSpotlightView().bindButtons();
        });
    }

    /**
     * Binds actions related to general analytics that are used on all pages
     */
    bindAnalytics() {
        storeUtmParams(); // store the UTM params in the context for later use
        storeUrlHash();

        // track Criteo events (@see _criteo_tracking.html)
        const remarketingObject = getRemarketingObject();
        if ($.isPlainObject(remarketingObject)) {
            trackRemarketingEvent(
                remarketingObject.event,
                remarketingObject.opt_productContents,
                remarketingObject.opt_transactionId,
                remarketingObject.opt_isFavoriting,
            );
        }

        $('#js-promo-banner .js-promo-banner-cta').on('click', 'a', (e) => {
            const $link = $(e.currentTarget);
            const ctaName = $link.data('cta-name') || $link.text().trim();

            logAmplitudeLinkClickEvent(ctaName, $link.attr('href'), BannerPosition.GLOBAL_PROMO_BANNER);
        });

        if (wasAccountCreated()) {
            trackAccountCreated();
        }
    }

    /**
     * Binds actions tied to authentication
     */
    bindAuthActions() {
        const tosappView = new TermsOfServiceAndPrivacyPolicyView();
        tosappView.showBanner();

        if (!isAuthenticated()) {
            // TODO: (CHAIR-15029) only load this when we need to render the buttons
            // load the Facebook SDK asynchronously
            (function (d, s, id) {
                const fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                const js = d.createElement(s);
                js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        }
    }

    /**
     * Binds actions tied to the cookie banner
     */
    bindCookieBanner() {
        const cookieBannerView = new CookieBannerView();

        if (cookieBannerView.isVisible()) {
            cookieBannerView.getCookieBanner().on('cookiebanner:close', attemptToShowPromotedListingsPopup);
        } else {
            attemptToShowPromotedListingsPopup();
        }
    }

    /**
     * Hook for subclasses to override so they can bind actions
     */
    bindPageActions() {}

    /**
     * Hook for subclasses to override so they can bind analytics to suit their needs
     */
    bindPageAnalytics() {}

    /**
     * Binds actions related to promotional elements (iOS banners, email collection, etc.)
     */
    bindPromos() {
        if (shouldDisplayEmailMarketingModal()) {
            import('chairisher/view/promo/marketingemailmodal').then(({ default: showMarketingEmailModal }) => {
                showMarketingEmailModal();
            });
        }

        const $scrollerContainer = $('#js-promo-banner .js-scroller');
        if ($scrollerContainer.length) {
            const $children = $scrollerContainer.children('.js-scroller-slide');
            if ($children.length > 1) {
                const clonedChildren = [];
                $.each($children, (i) => {
                    clonedChildren.push($children.eq(i).clone(true));
                });
                $scrollerContainer.append(clonedChildren);

                const scroller = new RepeatingScroller({
                    container: $scrollerContainer.get(0),
                    controllerContainerWrapperEl: $scrollerContainer
                        .find('.js-repeating-scroller-controls-wrapper')
                        .get(0),
                    children: $scrollerContainer.children('.js-scroller-slide').get(),
                    childrenHaveImages: false,
                    shouldForceAutoScroll: true,
                    slideDurationInMs: 9000,
                    transitionDurationInMs: 750,
                });
            }
        }
    }

    /**
     * Binds actions related to the on-page MiniCart
     */
    bindCartAction() {
        $('#js-cart-count').text(getCartCount() || '');
    }

    /**
     * This is left over from when we had multiple sites using the same codebase.
     * This could be consolidated with bindPageActions() now.
     */
    bindChairishActions() {
        // track clicks on the masthead's "consign with us" link
        $('.js-consignment-link').on('click', function () {
            logAmplitudeLinkClickEvent(
                $(this).text().trim(), // link text
                $(this).attr('href'), // link href attribute
                GlobalPosition.HEADER, // link position
            );
        });

        // Footer Mailing List Signup Module
        bindInlineEmailSignup({
            captureType: 'footer',
            formSelector: '#js-chairish-footer-email-collector-form',
            emailTrackingCallback() {
                trackSuccessfulEmailFooterCollection();
            },
        });

        // vacation modal
        $('.js-vacation-settings').on('click', (e) => {
            e.preventDefault();
            import('chairisher/view/vacationmodal').then(({ default: VacationModalView }) => {
                const vacationModal = new VacationModalView();
                vacationModal.bind();
                vacationModal.open();
            });
        });
    }

    /**
     * Binds actions for generic components present on all sites
     */
    bindComponents() {
        zendeskWebWidgetInst.bind();

        // Enable the close button on bootstrap alerts
        $('.alert').alert();
        $('.messages').alert();

        FavoriteProductView.setFavoriteButtonSelector('.js-favorite-button');
        FavoriteProductView.setProductFavoriteCountSelector('.js-product-favorite-count');
        FavoriteProductView.updateFavoriteCount();

        if (isAuthenticated()) {
            FavoriteProductView.updateFavoriteProductIds().done(() => {
                FavoriteProductView.drawButtons();
                this.productGrid?.renderFolderTrigger();
            });
        } else if (canRenderFavoriteHearts()) {
            $('.js-favorite-button.transparent').removeClass('transparent');
        }

        const favoriteAction = function (e) {
            const $button = $(e.currentTarget);
            const $product = $button.closest('[data-product-id]');
            const productId = $product.data('product-id');
            const isPurchasable = $product.data('is-purchasable');
            const isSeller = $product.data('is-seller');
            FavoriteProductView.setFavoriteTrackingLabel($button.data('tracking-label'));
            FavoriteProductView.toggleProductId(productId, undefined, isPurchasable);

            // update the ui
            FavoriteProductView.drawButton(FavoriteProductView.isProductIdFavorited(productId), productId);
            if (!isSeller) {
                FavoriteProductView.updateProductFavoriteCount(productId);
            }
            FavoriteProductView.updateFavoriteCount();

            const gridProductView = $product.data('gridProductView');

            if (gridProductView) {
                if (FavoriteProductView.isProductIdFavorited(productId)) {
                    gridProductView.renderFolderTrigger();
                } else {
                    gridProductView.removeFolderTrigger(true);
                }
            }
        };

        const favoriteButtonSelector = FavoriteProductView.getFavoriteButtonSelector();
        $('.js-product-async').on('click', favoriteButtonSelector, (e) => {
            e.preventDefault();
            e.stopPropagation(); // prevent the event from bubbling up to the PDP link
            favoriteAction(e);
        });

        // track products that are not loaded asynchronously and exclude those that *could* be to avoid double binding
        const nonAsyncSelector = `${favoriteButtonSelector}:not(.js-product-async ${favoriteButtonSelector})`;

        $(nonAsyncSelector).on('click', (e) => {
            e.preventDefault();
            e.stopPropagation(); // prevent the event from bubbling up to the PDP link
            favoriteAction(e);
        });

        // fix bootstrap popover issue (@see https://github.com/twbs/bootstrap/issues/16732)
        $('body').on('hidden.bs.popover', (e) => {
            $(e.target).data('bs.popover').inState.click = false;
        });

        const $document = $(document);

        // todo: place this more elegantly in a form class
        $document.on('keydown', '.js-boolean-input', function (e) {
            const code = e.which;
            if (KeyUtils.isSpacebar(code) || KeyUtils.isEnter(code)) {
                e.preventDefault();
                $(this).closest('.js-boolean-input').click();
            }
        });

        // Fixes modal scrolling page to top on open.
        $document.on('hidden.bs.modal', () => {
            ViewportUtil.unlockViewport();
        });

        $document.on('shown.bs.modal', () => {
            ViewportUtil.lockViewport();
        });

        //
        // Cart Icon Binding
        //
        $('.js-cart-link').on('click', (e) => {
            e.preventDefault();

            import('chairisher/component/cart').then(({ default: cartDrawerInstance }) => {
                if (!cartDrawerInstance.isInitialized()) {
                    cartDrawerInstance.initialize().done(() => {
                        cartDrawerInstance.show();
                    });
                } else {
                    // N.B. currently this never runs since cartDrawerInstance destroys itself after it's closed,
                    // meaning cartDrawerInstance.isInitialized() always returns false.
                    // If we changed that, then this clause would run on subsequent opens (cart would appear instantly).
                    cartDrawerInstance.show();
                }
            });
        });
    }

    /**
     * Binds actions that help users navigate the site
     */
    bindNavigation() {
        NavigationView.bind(); // Product Navigation
        const $autocompleteQueryEl = $('#js-global-nav .js-autocomplete-query');
        $autocompleteQueryEl.attr(
            'placeholder',
            isMaxPhone() ? 'Search Chairish' : 'Search products, brands, and shops',
        );

        new GlobalSearchView({
            $cancelEl: $('#js-global-nav .js-btn-cancel'),
            $queryEl: $autocompleteQueryEl,
            menuChoiceHasThumbnail: false,
            placeholderIndex: isCollection() || isShop() ? 2 : 1,
            shouldBoldNonQueryTextResults: true,
            shouldHideAutocompleteText: true,
        }).bind();
    }

    /**
     * Updates the context with account data.
     */
    async updateContextWithUserData() {
        const accountDataResponse = await getAccountDataPromise();
        if (!accountDataResponse.ok) {
            throw new FetchError({
                method: 'GET',
                status: accountDataResponse.status,
                statusText: accountDataResponse.statusText,
                url: accountDataResponse.url,
            });
        }
        const accountData = await accountDataResponse.json();

        runABTests(getTestObjects(accountData.AB_TESTS));

        $.extend(window.chairisher.context, accountData);
        $('#js-promo-banner').html(accountData.global_banner_html);

        // ensure every form has a CSRF token and that it matches the CSRF cookie
        ensureCsrf();

        $(document.body).append(['performance_pixels_html', 'tracking_pixels_html'].map((key) => accountData[key]));
        if (!accountData.IS_OPTED_OUT_OF_PERFORMANCE_COOKIE) {
            writeUtmzCookie(document);
        }

        if (isIdentified()) {
            GoogleAnalyticsUtils.identifyUser(getGuid());
        }

        if (isAuthenticated()) {
            setIsOptedOutOfCookie(getCookiesPreferenceCode(), accountData.HAS_SET_COOKIES_PREFERENCES);
            setIsOptedOutOfCookie(getAdvertisingCookiesCode(), accountData.IS_OPTED_OUT_OF_ADVERTISING_COOKIE);
            setIsOptedOutOfCookie(getFunctionalCookiesCode(), accountData.IS_OPTED_OUT_OF_FUNCTIONAL_COOKIE);
            setIsOptedOutOfCookie(getPerformanceCookiesCode(), accountData.IS_OPTED_OUT_OF_PERFORMANCE_COOKIE);

            const $redemptionButton = $('#js-global-nav .js-reward-redemption-action');
            if ($redemptionButton.length > 0 && accountData.redeemable_reward_amount) {
                $redemptionButton
                    .text(`Redeem ${accountData.redeemable_reward_amount} ${accountData.reward_program_name}`)
                    .parent()
                    .removeClass('hidden');
            }

            FavoritesNavMenu.bind(accountData);
        }
        FavoritesNavMenu.favoritesNavIcon();

        const advertisingCookieValue = isOptedOutOfCookie(getAdvertisingCookiesCode());
        PaypalStoreOffer.excludeUser(!!(advertisingCookieValue || accountData.IS_RET_BUYER));
    }
}

export default Controller;
